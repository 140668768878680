<template>
  <div v-frag>
    <v-container
      id="page"
      fluid
      class="pb-8"
      v-if="currentSection === 'start' && getOrder.orderType"
    >
      <ProjectAbout
        :items="projectTypes"
        :order="getOrder"
        @onChange="onChangeProjectAbout($event)"
      />
      <v-row
        v-if="currentSection === 'start'"
        class="pt-4"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex justify-start">
          <v-row>
            <v-col cols="6" class="d-flex justify-start">
              <v-btn right plain outlined @click="startOver">
                Start Over
              </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn class="mr-4" left plain outlined @click="nextStep(1)">
                Back
              </v-btn>
              <v-btn
                right
                plain
                outlined
                @click="currentSection = 'add'"
                :disabled="isDisabled"
              >
                Next
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      id="page"
      fluid
      class="pb-8"
      v-if="
        (currentSection === 'add' || currentSection === 'finish') &&
        getOrder.orderType
      "
    >
      <ProjectAboutFilm
        :items="filmTypes"
        :order="getOrder"
        @onChange="onChangeProjectAboutFilm($event)"
        @onChangeSection="changeSection($event)"
      />

      <v-row
        v-if="currentSection === 'finish'"
        class="pt-4"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex justify-start">
          <v-row>
            <v-col cols="6" class="d-flex justify-start">
              <v-btn right plain outlined @click="startOver">
                Start Over
              </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn class="mr-4" left plain outlined @click="nextStep(1)">
                Back
              </v-btn>
              <v-btn
                right
                plain
                outlined
                @click="nextStep(3)"
                :disabled="isDisabledFilm"
              >
                Next
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import frag from 'vue-frag'
import ProjectAbout from '@/components/ProjectAbout'
import ProjectAboutFilm from '@/components/ProjectAboutFilm'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'about',
  title: 'About',
  directives: {
    frag
  },
  components: {
    ProjectAbout,
    ProjectAboutFilm
  },
  props: {},
  data: function () {
    return {
      currentSection: 'start',
      order: { ...this.getOrder },
      isDisabled: true,
      projectTypes: [
        { text: 'Advertisement', value: 'Advertisement' },
        { text: 'Music Video', value: 'Music Video' },
        { text: 'Short Film', value: 'Short Film' },
        {
          text: 'Other (Episodic, Feature, etc.)',
          value: 'Other (Episodic, Feature, etc.)'
        }
      ],
      filmTypes: [
        { text: '16mm', value: '16mm' },
        { text: 'S16mm', value: 'S16mm' },
        { text: '35mm 4-perf', value: '35mm 4-perf' },
        { text: '35mm 3-perf', value: '35mm 3-perf' },
        { text: '35mm 2-perf', value: '35mm 2-perf' }
      ]
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder'
    }),
    isDisabledFilm () {
      if (this.getOrder.film && this.order.film.length) {
        return false
      }
      return true
    }
  },
  async created () {
    this.checkParams()
  },
  methods: {
    onChangeProjectAbout (event) {
      this.isDisabled = event.isDisabled
      this.$emit('onChangeProjectAbout', {
        order: event.project
      })
    },
    onChangeProjectAboutFilm (event) {
      this.$emit('onChangeProjectAbout', {
        order: event.project
      })
    },
    changeSection (event) {
      var myDiv = document.getElementById('page')
      myDiv.scrollTop = 0
      window.scrollTo(0, 0)
      this.currentSection = event.section
    },
    nextStep (n) {
      this.$emit('nextStep', {
        n: n
      })
    },
    startOver () {
      this.$emit('startOver')
    },
    async checkParams () {
      this.order = { ...this.getOrder }
      this.isDisabled = !(
        this.getOrder.projectTitle &&
        this.getOrder.projectType &&
        this.getOrder.shootDates &&
        this.getOrder.dropDate
      )
    }
  }
}
</script>
