<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">Tell us about your film</h4>
      </v-col>
    </v-row>
    <div v-if="currentSection === 'start'">
      <v-row class="pt-1 pb-0" align="center" justify="center"> </v-row>
      <v-row class="pt-0" align="center" justify="center">
        <v-col
          cols="6"
          class="pt-0 d-flex flex-column justify-center align-center"
        >
          <p
            class="error--text"
            v-for="(error, i) in fimlNeedsErrors"
            v-bind:key="i"
          >
            {{ error }}
          </p>
        </v-col>
      </v-row>
      <v-row class="pt-0" align="center" justify="center">
        <v-col cols="6" class="d-flex pt-0">
          <v-select
            v-model="currentItem.filmFormat"
            :items="items"
            label="Film Format"
            @input="onChange($event, 'filmFormat')"
            @blur="$v.currentItem.filmFormat.$touch()"
            required
            :error-messages="filmFormatErrors"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="pt-2" align="center" justify="center">
        <v-col cols="4" class="d-flex">
          <v-radio-group v-model="currentItem.colorOrBW" row>
            <v-radio label="Color" value="Color"></v-radio>
            <v-radio label="Black & White" value="BW"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="4" class="d-flex">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="4" class="d-flex">
          <v-radio-group v-model="currentItem.reversalOrNegative" row>
            <v-radio label="Negative" value="Negative"></v-radio>
            <v-radio label="Reversal" value="Reversal"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="pt-2" align="center" justify="center">
        <v-col cols="4" class="d-flex">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="pt-4" align="center" justify="center">
        <v-col cols="2" class="d-flex">
          <v-text-field
            v-model="currentItem.totalFootage"
            label="Estimated Total Footage"
            @input="onChange($event, 'totalFootage')"
            @blur="$v.currentItem.totalFootage.$touch()"
            required
            type="number"
            suffix="ft"
            :error-messages="totalFootageErrors"
            hint="Numbers only"
            persistent-hint
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="d-flex">
          <v-text-field
            v-model="currentItem.numRolls"
            label="Number of Camera Rolls"
            @input="onChange($event, 'numRolls')"
            @blur="$v.currentItem.numRolls.$touch()"
            required
            type="number"
            :error-messages="numRollsErrors"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pt-4" align="center" justify="center">
        <v-btn
          v-if="order.film.length && isEditing === false"
          text
          @click="onCancel"
          >skip</v-btn
        >
        <v-btn
          v-if="order.film.length && isEditing === true"
          text
          @click="onCancel"
          >cancel</v-btn
        >
        <v-btn text color="success" @click="onSave" :disabled="isDisabled"
          >save</v-btn
        >
      </v-row>
    </div>

    <div class="pb-12" v-if="currentSection === 'add'">
      <v-row class="pt-8 pb-4" align="center" justify="center">
        <v-col cols="6" class="d-flex justify-center">
          <v-btn text large color="success" @click="onAdd"
            ><v-icon left small>mdi-plus</v-icon>Add Another Film Format</v-btn
          >
        </v-col>
      </v-row>
      <div class="order-list">
        <v-row class="pb-0" align="center" justify="center">
          <v-col cols="6" class="pt-0 pb-0 d-flex align-center justify-center">
            <v-list dense>
              <v-list-item v-for="(item, index) in film" v-bind:key="index">
                <v-list-item-content>
                  <v-list-item-title v-text="item.summary"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    class="ml-12 mr-0"
                    small
                    plain
                    color="success"
                    @click="onEdit(item)"
                    >Edit</v-btn
                  >
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn
                    class="ml-0"
                    small
                    plain
                    color="warning"
                    @click="onDelete(item)"
                    >delete</v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
import { v4 as uuid } from 'uuid'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'ProjectAbout',
  props: {
    items: Array,
    order: Object
  },
  data: function () {
    return {
      currentSection: 'start',
      isEditing: false,
      isDisabled: true,
      project: {
        film: this.order.film ? [...this.order.film] : [],
        scanning: this.order.scanning
          ? { ...this.order.scanning }
          : {
            isNeeded:
                this.order.orderType === 'Scanning Only' ||
                this.order.orderType === 'Processing & Scanning',
            resolution: '2K',
            gateAperture: 'Full aperture',
            frameRate: '24fps',
            additionalScanningInstructions: null,
            editorialMedia: null,
            editorialMediaResolution: null,
            editorialMediaCodec: null,
            editorialMediaColor: null
          }
      },
      currentItem: {
        uuid: uuid(),
        summary: null,
        filmFormat: null,
        colorOrBW: 'Color',
        reversalOrNegative: 'Negative',
        totalFootage: null,
        numRolls: 1,
        processing: {
          isNeeded:
            this.order.orderType === 'Processing Only' ||
            this.order.orderType === 'Processing & Scanning',
          processingInstructions: 'Normal',
          additionalProcessingInstructions: null
        },
        scanning: {
          isNeeded:
            this.order.orderType === 'Scanning Only' ||
            this.order.orderType === 'Processing & Scanning',
          resolution: '2K',
          gateAperture: 'Full aperture',
          frameRate: '24fps',
          additionalScanningInstructions: null,
          editorialMedia: null,
          editorialMediaResolution: null,
          editorialMediaCodec: null,
          editorialMediaColor: null
        }
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder',
      film: 'order/getFilm'
    }),
    showList () {
      if (this.film.length) {
        return true
      }
      return false
    },
    filmFormatErrors () {
      const errors = []

      if (!this.$v.currentItem.filmFormat.$dirty) return errors

      if (!this.$v.currentItem.filmFormat.required) {
        errors.push('Film Format is required')
      }

      return errors
    },
    totalFootageErrors () {
      const errors = []

      if (!this.$v.currentItem.totalFootage.$dirty) return errors

      if (!this.$v.currentItem.totalFootage.required) {
        errors.push('Estimated Total Footage is required')
      }
      if (!this.$v.currentItem.totalFootage.numeric) {
        errors.push('Positive numbers only')
      }

      return errors
    },
    numRollsErrors () {
      const errors = []

      if (!this.$v.currentItem.numRolls.$dirty) return errors

      if (!this.$v.currentItem.numRolls.required) {
        errors.push('Number of Rolls is required')
      }

      return errors
    },
    fimlNeedsErrors () {
      const errors = []
      if (
        !this.$v.currentItem.processing.isNeeded.$dirty &&
        !this.$v.currentItem.scanning.isNeeded.$dirty
      ) {
        return errors
      }

      if (
        !this.currentItem.processing?.isNeeded &&
        this.getOrder.orderType === 'Processing Only'
      ) {
        errors.push('Processing is required')
      }

      if (
        !this.currentItem.scanning?.isNeeded &&
        this.getOrder.orderType === 'Scanning Only'
      ) {
        errors.push('Scanning is required')
      }
      if (
        !this.currentItem.scanning?.isNeeded &&
        !this.currentItem.processing?.isNeeded &&
        this.getOrder.orderType === 'Processing & Scanning'
      ) {
        errors.push('Processing and/or Scanning is required')
      }

      return errors
    }
  },
  mixins: [validationMixin],
  validations: {
    currentItem: {
      totalFootage: {
        numeric,
        required
      },
      numRolls: {
        required
      },
      filmFormat: {
        required
      },
      processing: {
        isNeeded: {
          required
        }
      },
      scanning: {
        isNeeded: {
          required
        }
      }
    }
  },
  methods: {
    ...mapActions('order', ['setOrder', 'removeFilm', 'addFilm', 'updateFilm']),
    onSave () {
      this.isDisabled = true
      const summary = `${this.currentItem.filmFormat} / ${this.currentItem.colorOrBW} /
              ${this.currentItem.reversalOrNegative} /
              ${this.currentItem.totalFootage}`
      this.currentItem.summary = summary

      if (!this.currentItem.scanning) {
        this.currentItem.scanning = {
          isNeeded: false,
          resolution: '2K',
          gateAperture: 'Full aperture',
          frameRate: '24fps',
          additionalScanningInstructions: null,
          editorialMedia: null,
          editorialMediaResolution: null,
          editorialMediaCodec: null,
          editorialMediaColor: null
        }
      }
      if (!this.currentItem.processing) {
        this.currentItem.processing = {
          isNeeded: false,
          processingInstructions: 'Normal',
          additionalProcessingInstructions: null
        }
      }
      const index = this.order.film.findIndex(
        (element) => element.uuid === this.currentItem.uuid
      )

      if (index !== -1) {
        this.updateFilm({
          uuid: this.currentItem.uuid,
          film: this.currentItem
        })
      } else {
        this.addFilm({
          film: this.currentItem
        })
      }
      this.currentItem = {
        uuid: uuid(),
        summary: null,
        filmFormat: null,
        colorOrBW: 'Color',
        reversalOrNegative: 'Negative',
        totalFootage: null,
        numRolls: 1,
        processing: {
          isNeeded: false,
          processingInstructions: 'Normal',
          additionalProcessingInstructions: null
        },
        scanning: {
          isNeeded: false,
          resolution: '2K',
          gateAperture: 'Full aperture',
          frameRate: '24fps',
          additionalScanningInstructions: null,
          editorialMedia: null,
          editorialMediaResolution: null,
          editorialMediaCodec: null,
          editorialMediaColor: null
        }
      }
      this.currentSection = 'add'
      this.$emit('onChangeSection', {
        section: 'finish'
      })
    },
    onCancel () {
      this.currentItem = {
        uuid: uuid(),
        summary: null,
        filmFormat: null,
        colorOrBW: 'Color',
        reversalOrNegative: 'Negative',
        totalFootage: null
      }

      this.currentSection = 'add'
      this.$emit('onChangeSection', {
        section: 'finish'
      })
    },
    onAdd () {
      this.isEditing = true
      this.currentItem = {
        uuid: uuid(),
        summary: null,
        filmFormat: null,
        colorOrBW: 'Color',
        reversalOrNegative: 'Negative',
        totalFootage: null,
        numRolls: 1,
        processing: {
          isNeeded:
            this.order.orderType === 'Processing Only' ||
            this.order.orderType === 'Processing & Scanning',
          processingInstructions: 'Normal',
          additionalProcessingInstructions: null
        },
        scanning: {
          isNeeded: this.order.orderType === 'Scanning Only',
          resolution: '2K',
          gateAperture: 'Full aperture',
          frameRate: '24fps',
          additionalScanningInstructions: null,
          editorialMedia: null,
          editorialMediaResolution: null,
          editorialMediaCodec: null,
          editorialMediaColor: null
        }
      }
      this.currentSection = 'start'
      this.$emit('onChangeSection', {
        section: 'add'
      })
    },
    onDelete (item) {
      const index = this.film.findIndex((element) => element.uuid === item.uuid)
      if (index !== -1) {
        this.removeFilm({
          uuid: item.uuid
        })
        this.currentSection = 'add'
        this.$emit('onChangeSection', {
          section: 'finish'
        })
      }
    },
    onEdit (item) {
      this.isEditing = true
      this.isDisabled = false
      this.currentItem = { ...item }
      this.currentSection = 'start'
      this.$emit('onChangeSection', {
        section: 'add'
      })
    },
    onChange (e, field) {
      if (field === 'processing') {
        this.$v.currentItem.processing.isNeeded.$touch()
      } else if (field === 'scanning') {
        this.$v.currentItem.scanning.isNeeded.$touch()
      } else if (this.$v.currentItem[field]) {
        this.$v.currentItem[field].$touch()
      }

      const isValid =
        this.$v.currentItem.filmFormat.required &&
        this.$v.currentItem.totalFootage.required &&
        (this.currentItem.scanning.isNeeded ||
          this.currentItem.processing.isNeeded)

      this.isDisabled = !isValid
    }
  }
}
</script>

<style scoped lang="scss"></style>
