<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">Tell us about your project</h4>
      </v-col>
    </v-row>
     <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
      <v-radio-group @change="onChange($event, 'orderFor')" v-model="project.orderFor">
        <v-radio label="Non-Student Project" value="Non-student Project"></v-radio>
        <v-radio label="Student Project" value="Student Project"></v-radio>
      </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-text-field
          v-model="project.projectTitle"
          @input="onChange($event, 'projectTitle')"
          @blur="$v.project.projectTitle.$touch()"
          label="Project Title"
          required
          :error-messages="projectTitleErrors"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-select
          v-model="project.projectType"
          @input="onChange($event, 'projectType')"
          @blur="$v.project.projectType.$touch()"
          :items="items"
          label="Project Type"
          required
          :error-messages="projectTypeErrors"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-text-field
          v-model="project.shootLocation"
          @input="onChange($event, 'shootLocation')"
          label="Shoot Location"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-menu
          ref="shootDateMenu"
          v-model="shootDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedShootDateFormatted"
              label="Shoot Dates"
              prepend-icon="mdi-calendar"
              readonly
              @input="$v.project.shootDates.$touch()"
              @blur="$v.project.shootDates.$touch()"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="project.shootDates"
            multiple
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text @click="shootDateMenu = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveShootDate(project.shootDates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-menu
          ref="dropDateMenu"
          v-model="dropDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDropDateFormatted"
              label="Estimated Film Drop Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              @input="$v.project.dropDate.$touch()"
              @blur="$v.project.dropDate.$touch()"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="project.dropDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text @click="dropDateMenu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="saveDropDate(project.dropDate)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ProjectAbout',
  props: {
    items: Array,
    order: Object
  },
  data: function () {
    return {
      shootDateMenu: false,
      dropDateMenu: false,
      project: {
        orderFor: this.order.orderFor,
        projectTitle: this.order.projectTitle,
        projectType: this.order.projectType,
        shootLocation: this.order.shootLocation,
        shootDates: this.order.shootDates,
        dropDate: this.order.dropDate
      }
    }
  },
  computed: {
    computedDropDateFormatted () {
      return this.formatDate(this.project.dropDate)
    },
    computedShootDateFormatted () {
      return this.project.shootDates.map((element) => {
        return ' ' + this.formatDate(element)
      })
    },
    projectTitleErrors () {
      const errors = []

      if (!this.$v.project.projectTitle.$dirty) return errors

      if (!this.$v.project.projectTitle.required) {
        errors.push('Project Title is required')
      }

      return errors
    },
    projectTypeErrors () {
      const errors = []

      if (!this.$v.project.projectType.$dirty) return errors

      if (!this.$v.project.projectType.required) {
        errors.push('Project Type is required')
      }

      return errors
    },
    shootDatesErrors () {
      const errors = []

      if (!this.$v.project.shootDates.$dirty) return errors

      if (!this.project.shootDates.length) {
        errors.push('Shoot Dates is required')
      }

      return errors
    },
    dropDateErrors () {
      const errors = []

      if (!this.$v.project.dropDate.$dirty) return errors

      if (!this.$v.project.dropDate.required) {
        errors.push('Drop Dates is required')
      }

      return errors
    }
  },
  mixins: [validationMixin],
  validations: {
    project: {
      projectTitle: {
        required
      },
      projectType: {
        required
      },
      shootDates: {
        required
      },
      dropDate: {
        required
      }
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    saveDropDate (item) {
      this.$v.project.dropDate.$touch()
      this.project.dropDate = item
      const isValid =
        this.$v.project.projectTitle.required &&
        this.$v.project.projectType.required &&
        this.project.shootDates.length &&
        this.$v.project.dropDate.required

      this.$emit('onChange', {
        project: { ...this.order, ...this.project },
        isDisabled: !isValid
      })
      this.dropDateMenu = false
    },
    saveShootDate (item) {
      this.$v.project.shootDates.$touch()
      this.project.shootDates = [...item]
      const isValid =
        this.$v.project.projectTitle.required &&
        this.$v.project.projectType.required &&
        this.project.shootDates.length &&
        this.$v.project.dropDate.required

      this.$emit('onChange', {
        project: { ...this.order, ...this.project },
        isDisabled: !isValid
      })
      this.shootDateMenu = false
    },
    onChangeStudent () {
      this.$emit('onChangeStudent', {
        orderFor: this.orderFor
      })
    },
    onChange (e, field) {
      if (this.$v.project[field]) {
        this.$v.project[field].$touch()
      }
      console.log(field)
      const isValid =
        this.$v.project.projectTitle.required &&
        this.$v.project.projectType.required &&
        this.project.shootDates.length &&
        this.$v.project.dropDate.required
      console.log(this.project)
      this.$emit('onChange', {
        project: { ...this.order, ...this.project },
        isDisabled: !isValid
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
